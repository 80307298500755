// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

.no-tailwind {
    all: unset;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $paper;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $paper;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

.error {
    color:#f44336;
}

.w-100 {
    width: 100%;
}

.w-95 {
    width: 95%;
}

.w-90 {
    width: 90%;
}
.icon-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.profile-score-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.icon-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.icon-left-side-with-text {
    display: flow-root;
    margin-bottom: 1rem;
}

.row-left-side {
    float: left;
    width: 60%;
    display: flex;
    align-items:center;
}

.image-span {
    float: left;
    margin-right: 0.5rem;
}

.row-right-side {
    h1 {
        font-size: 1.5rem;
        line-height: 24px;
    }
}

.icon-right {
    position: absolute;
    float: right;
    right: 4rem;
}

.MuiTableRow-head {
    th {
        font-weight: bold;
    }
}

.close-button {
    margin-left: 24px;
    min-width: 20px;
    border: none;
    background: none;
    color: #c71612;
    outline: none;
    padding: 5px;

    svg {
      width: 14px;
      height: 14px;
    }

  }

.react-tel-input .form-control {
    width: 100%;
}

.text-disabled {
    & fieldset {
        border: none
    }
}

.margin-top {
    margin-top: 0.8rem;
}

.custom-file-upload {
    border-radius: 5%;
    display: inline-block;
    position: relative;
    padding: 6px;
    //cursor: pointer;
    //background: linear-gradient(270deg, #283556, #283556);
    margin-bottom: 25px;
}

.img-wrap {
    position: relative;
    width: 225px;
    height: 225px;
    overflow: hidden;
    border-radius: 2%;
}

.img-wrap-business {
    position: relative;
    width: 250px;
    height: 250px;
    overflow: hidden;
    border-radius: 2%;
}

.img-upload {
    img {
        object-fit: cover;
    }
}

img {
    width: auto;
    height: 100%;
}

h1 {
    font-weight: bold;
    font-size: 2em;
    line-height: 32px;
}

a button {
    -webkit-appearance: auto;
    background-color: #283556 !important;
    background-image: none;
}

.image-text-row {
    display: flex;
    align-items: center;
    width: 260px;
    .image-span {
        float: left;
        padding: 0px 0px 0px 0;
    }
}

.copy-text-row {
    display: flex;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer !important;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.MuiPagination-root {
    .Mui-selected {
        background-color: #283665 !important;
        color: #FFFFFF !important;
    }
    button {
      color: #283665 !important;
    }
}

.image-container {
    width: 100%;
    max-width: 500px;
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;
}

.image-container img {
    position: absolute;
    width: 100%;
    height: 95%;
    object-fit: cover;
    object-position: center;
}

.sub-icons {
    width: 18px;
    height: 18px;
}

.vertical-align-middle {
    vertical-align: middle;
}

.fix-profile-section {
    height: 4rem;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    list-style-type: none;
}
// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

.truncate-2 {
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
}

.no-text-capitalize {
    text-transform: none !important;
}

.truncate-1 {
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
}

.open-icon-button {
    position: fixed;
    top: 58px; /* Adjust the value to position the button vertically */
    left: 8px; /* Adjust the value to position the button horizontally */
    z-index: 9999; /* Ensure the button stays on top of other content */
    background-color: #ffffff; /* Button background color */
    border: none;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow effect */
  }
.truncate-chip {
    max-height: 3.5rem;
    overflow: hidden;
}

.media-body {
    flex: 1;
}

.align-self-center {
    align-self: center!important;

    svg {
        float: right!important;
    }
}

.Mui-expanded {
    margin: 0 !important;
}

.Mui-expanded:before {
    opacity: 1 !important;
}
